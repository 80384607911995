import React, {Component} from "react";
import Dropzone from "../dropzone/Dropzone";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import "./Upload.css";
import Progress from "../progress/Progress";
import {withAuthenticator} from 'aws-amplify-react';
import {API, Auth, I18n, Storage} from "aws-amplify";
import Visualization from "../visualization/Visualization";

let apiName = 'PianoPerformanceEvaluationAPI';


class Upload extends Component {

    highlight = {};
    resultCheckHandles = {};
    visualizationRefs = {};

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            successfullyUploaded: false,
            reference_key: '',
            processedUploads: {},
            results: {},
            referenceAudioUrl: ""
        };

        this.referenceUploadInput = React.createRef();

        this.onReferenceAdded = this.onReferenceAdded.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.renderActions = this.renderActions.bind(this);
        this.onFileUploaded = this.onFileUploaded.bind(this);
    }

    async componentDidMount() {
        const identityId = await (await Auth.currentCredentials()).identityId;

        Auth.currentAuthenticatedUser()
            .then(user => {
                console.log(user);
                this.setState({user});
            });

        console.log('Returned info: ', identityId);
        this.setState({identityId});
    }

    onFileSelected(file, isReference) {

        this.setState({uploadInProgress: true, uploadFieldValue: file.name});

        Storage.put(file.name, file, {
            level: 'private',
            contentType: 'audio/mpeg',
            progressCallback: (progress) => {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);

                const copy = {...this.state.uploadProgress};
                copy[file.name] = {
                    state: "pending",
                    percentage: progress.loaded / progress.total * 100
                };
                this.setState({uploadProgress: copy});
            }
        })
            .then((result) => {

                const copy = {...this.state.uploadProgress};
                copy[file.name] = {state: "done", percentage: 100};
                this.setState({uploadProgress: copy});

                this.onFileUploaded(result.key, isReference)
            })
            .catch(err => {

                const copy = {...this.state.uploadProgress};
                copy[file.name] = {state: "error", percentage: 0};
                this.setState({uploadProgress: copy});

                this.setState({uploadInProgress: false});
                console.log(err)
            });
    }

    onFileUploaded(key, isReference) {
        API.post(apiName, '/evaluate-performance', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                identityId: this.state.identityId,
                filename: key,
                email: this.state.user.attributes.email,
                reference: !isReference ? this.state.reference_key : undefined,
                isReference: isReference
            }
        }).then(response => {
            if (!isReference) {
                const result_key = this.state.reference_key + "_" + key;
                let newMeta = this.state.results;
                newMeta[result_key] = {imgUrl: response.imgUrl, audioUrl: response.audioUrl, midiUrl: response.midiUrl};
                this.setState({results: newMeta, uploadInProgress: false, uploadFieldValue: undefined});
                const handle = setInterval(this.checkEvaluationComplete.bind(this, result_key), 5000);
                this.resultCheckHandles[result_key] = handle;
            } else {
                this.setState({referenceAudioUrl: response.audioUrl});
            }
        }).catch(error => {
            this.setState({uploadInProgress: false, uploadFieldValue: undefined});
            console.error(error);
        });
    }

    checkEvaluationComplete(filename) {
        Storage.list('img/' + filename + '.png', {level: 'private'})
            .then(result => {
                if (result.length > 0) {
                    const newProcessedUploads = this.state.processedUploads;
                    newProcessedUploads[filename] = new Date().getTime();
                    this.setState({processedUploads: newProcessedUploads});
                    clearInterval(this.resultCheckHandles[filename])
                }
            })
            .catch(err => console.log(err));
    }

    fileListToArray(list) {
        const array = [];
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    }

    onReferenceAdded(evt) {
        const files = this.fileListToArray(evt.target.files);
        this.setState(prevState => ({
            reference_key: files[0].name
        }));
        this.onFileSelected(files[0], true)
    }

    onFilesAdded(files) {
        this.setState(prevState => ({
            files: prevState.files.concat(files)
        }));
    }

    async uploadFiles() {
        this.setState({uploadProgress: {}, uploading: true});
        const promises = [];
        this.state.files.forEach(file => {
            promises.push(this.onFileSelected(file));
        });
        try {
            await Promise.all(promises);

            this.setState({successfullyUploaded: true, uploading: false});
        } catch (e) {
            // Not Production ready! Do some error handling here instead...
            this.setState({successfullyUploaded: true, uploading: false});
        }
    }

    renderProgress(filename, image_filename) {
        const uploadProgress = this.state.uploadProgress[filename];
        if (this.state.uploading || this.state.successfullyUploaded) {
            const alt = this.state.processedUploads[image_filename] ? 'done' : I18n.get('processing');
            const src = this.state.processedUploads[image_filename]
                ? "baseline-check_circle_outline-24px.svg"
                : "grid.svg";
            return (
                <div className="ProgressWrapper">
                    <Progress progress={uploadProgress ? uploadProgress.percentage : 0}/>
                    <img
                        className="CheckIcon"
                        alt={alt}
                        title={alt}
                        src={src}
                        style={{
                            opacity:
                                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
                        }}
                    />
                </div>
            );
        }
    }

    renderActions() {
        if (this.state.successfullyUploaded) {
            return (
                <button
                    disabled={this.state.files.length === 0}
                    onClick={() =>
                        this.setState({files: [], successfullyUploaded: false})
                    }
                >
                    {I18n.get('Clear')}
                </button>
            );
        } else {
            return (
                <button
                    disabled={this.state.files.length <= 0 || this.state.uploading}
                    onClick={this.uploadFiles}
                >
                    {I18n.get('Submit')}
                </button>
            );
        }
    }

    onPlaybackStarted(key) {
        console.log(this.visualizationRefs);

        for (let pieceId in this.visualizationRefs) {
            if (pieceId !== key) this.visualizationRefs[pieceId].stopPlayback();
        }
    }

    createVisualizationRef(element) {
        if (element) {
            this.visualizationRefs[element.props.pieceId] = element;
        }
    }

    renderProcessedUpload(key) {
        if (!this.state.results[key]) {
            return null;
        }
        const imageUrl = this.state.results[key].imgUrl;
        const audioUrl = this.state.results[key].audioUrl;
        const midiUrl = this.state.results[key].midiUrl;
        const subPiece = key.substring(key.indexOf("_"));
        const pieceId = subPiece.substring(1, subPiece.indexOf(".mp3"));

        return (
            <div className="result" key={key}>

                <Visualization key={key} audioURL={audioUrl} midiURL={midiUrl} imgURL={imageUrl}
                               pieceId={pieceId}
                               onPlaybackStarted={this.onPlaybackStarted.bind(this, pieceId)}
                               ref={this.createVisualizationRef.bind(this)}/>
                <div className="CardCloseButton"
                     title={I18n.get("Close")}
                     onClick={() => {
                         let newProcessedUploads = this.state.processedUploads;
                         delete newProcessedUploads[key];

                         let newFiles = this.state.files.filter((file) => file.name !== key.substring(1));
                         console.log(newFiles, key);

                         this.setState({
                             processedUploads: newProcessedUploads,
                             files: newFiles
                         });

                         if (newFiles.length <= 0) {
                             this.setState({successfullyUploaded: false})
                         }

                         delete this.visualizationRefs[pieceId];
                     }}>
                    <img src={"ic_close_48px_352270.svg"} style={{width: 24}}/>
                </div>
            </div>
        );
    }

    renderProcessedUploads() {
        let elts = [];
        let sorted = Object.entries(this.state.processedUploads).sort((a, b) => a[1] > b[1] ? -1 : 1);
        for (let e in sorted) {
            elts.push(this.renderProcessedUpload(sorted[e][0]));
        }
        return elts;
    }

    render() {
        return (
            <div className="CardContainer">
                <div className="Card">
                    <div className="Upload">
                        <span className="Title"></span>
                        <div className="CurrentReference"
                             style={{display: this.state.reference_key ? 'block' : 'none'}}>
                            {I18n.get("Current reference: ") + this.state.reference_key}
                        </div>
                        <div className="ReferenceUpload"
                             onClick={() => this.referenceUploadInput.current.click()}>
                            <span>{this.state.reference_key
                                ? I18n.get("Change reference")
                                : I18n.get("Select reference recording")}
                            </span>
                            <input
                                ref={this.referenceUploadInput}
                                className="FileInput"
                                type="file"
                                onChange={this.onReferenceAdded}
                            />
                        </div>
                        <div className="Content">
                            <div>
                                <Dropzone
                                    onFilesAdded={this.onFilesAdded}
                                    disabled={this.state.uploading || this.state.successfullyUploaded}
                                />
                            </div>
                            <div className="Files">
                                {this.state.files.map(file => {
                                    return (
                                        <div key={file.name} className="Row">
                                            <span className="Filename">{file.name}</span>
                                            {this.renderProgress(file.name, this.state.reference_key + "_" + file.name)}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="Actions">{this.renderActions()}</div>
                    </div>
                </div>
                <ReactCSSTransitionGroup
                    transitionName="example"
                    transitionEnterTimeout={800}
                    transitionLeaveTimeout={300}
                    transitionEnter={true}
                    transitionLeave={true}>
                    {this.renderProcessedUploads()}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

const signUpConfig = {
    hideAllDefaults: true,
    signUpFields: [
        {
            label: I18n.get('Name'),
            key: 'name',
            required: true,
            placeholder: I18n.get('Your Name'),
            type: 'text',
            displayOrder: 1,
        },
        {
            label: I18n.get('Email'),
            key: 'username',
            required: true,
            placeholder: I18n.get('Email'),
            type: 'email',
            displayOrder: 2,
        },
        {
            label: I18n.get('Password'),
            key: 'password',
            required: true,
            placeholder: I18n.get('Password'),
            type: 'password',
            displayOrder: 3,
        },
    ],
};

export default withAuthenticator(Upload, {signUpConfig});
