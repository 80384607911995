import React from 'react';
import styles from "./Visualization.module.css";

interface IVisualizationProps {
    audioURL: string;
    midiURL: string;
    imgURL: string;
    pieceId: string;
    onPlaybackStarted: () => void
}

export default class Visualization extends React.Component <IVisualizationProps, any> {

    private marginLeft = 63;
    private graphEnd = 450;
    private graphWidth = this.graphEnd - this.marginLeft;

    private audioPlayer = new Audio();
    private playbackInterval: any;
    private totalPieceLength = 0;

    private colorMap = {
        "wrong notes": "#483D8B",
        "unsteady tempo": "#6B8E23",
        "inexpressive": "#F4A460"
    };

    constructor(props: any) {
        super(props);
        this.state = {
            playbackPos: -1,
            playerWidth: 0,
            playerHeight: 0,
            playbackVisibility: "hidden",
            iconSource: "",
            playback: false,
            paused: false,
            annotations: []
        }
    }

    private determinePosInPiece = (event) => {
        let offsetX = event.nativeEvent.offsetX - this.marginLeft;

        this.audioPlayer.pause();
        clearInterval(this.playbackInterval);
        let pieceInPos = (offsetX / this.graphWidth) * this.totalPieceLength;

        if (pieceInPos < 0) pieceInPos = 0;

        this.audioPlayer.currentTime = pieceInPos;
        this.audioPlayer.play();

        this.playbackInterval = setInterval(
            () => {
                let playbackPos = this.marginLeft + this.audioPlayer.currentTime / this.totalPieceLength * this.graphWidth;
                this.setState(
                    {
                        playbackPos: playbackPos,
                        playbackVisibility: "visible"
                    })
            }, 500);

        this.audioPlayer.addEventListener("play", () => {
            this.setState({
                playback: true,
                paused: false,
                playerWidth: "2px"
            });

            this.props.onPlaybackStarted();
        });

        this.audioPlayer.addEventListener("pause", () => {
            this.setState({
                paused: true
            })
        });

        this.audioPlayer.addEventListener("ended", () => {
            this.setState({
                playback: false,
                playerWidth: 0
            });
        })

    };

    public componentWillUnmount(): void {
        this.audioPlayer.pause();
        clearInterval(this.playbackInterval);
    }

    public stopPlayback(): void {
        this.audioPlayer.pause();

            this.setState({paused: true});
    }

    public componentWillMount(): void {
        this.audioPlayer.src = this.props.audioURL;

        this.audioPlayer.addEventListener("loadeddata", () => {
            this.totalPieceLength = this.audioPlayer.duration;

            fetch("http://pn-rect.dyn-vpn.de:3002/annotation?taskId=" + this.props.pieceId)

                .then((response) => {
                    if (response.ok) {
                        response.json().then(json => {
                            console.log(json);
                            let annotations: any [] = [];
                            for (let ann of json) {
                                annotations.push(ann);
                            }

                            this.setState({annotations: annotations});
                        })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                });
        });
    }

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className={styles.main} style={{height: this.state.playerHeight + 40}}>
                <img src={this.props.imgURL} style={{
                    width: "500px", position: "absolute",
                    top: 0, left: 0
                }}
                     onLoad={(ev) => {
                         this.setState({playerHeight: ev.currentTarget.clientHeight - 40});
                     }}
                     onClick={this.determinePosInPiece.bind(this)}/>
                {this.state.annotations.map((annotation, i) => {
                    let offset = 0;
                    switch (annotation.annotation) {
                        case("unsteady tempo"):
                            offset = 4;
                            break;
                        case("wrong notes"):
                            offset = 8;
                            break;

                        default:
                            break;
                    }
                    return (<div key={i} className={styles.annotation}
                                 style={{
                                     left: this.marginLeft + annotation.start_time / this.totalPieceLength * this.graphWidth,
                                     width: (annotation.end_time - annotation.start_time) / this.totalPieceLength * this.graphWidth,
                                     top: this.state.playerHeight / 2 + offset,
                                     backgroundColor: this.colorMap[annotation.annotation]
                                 }} title={annotation.annotation}/>);
                })}

                <div style={{
                    left: this.state.playbackPos,
                    width: this.state.playerWidth,
                    height: this.state.playerHeight - 40,
                    visibility: this.state.playbackVisibility,
                }} className={styles.progress}/>
                <div className={styles.controls}>
                    <div className={styles.audio}>
                        <a href={this.props.audioURL} target={'_blank'}>audio</a>
                        <img style={{
                            visibility: this.state.playback ? "visible" : "hidden",
                            width: "0.4em", height: "0.4em"
                        }} src={this.state.paused ? require("./play.svg") : require("./pause.svg")}
                             onClick={() => {
                                 if (this.state.paused) {
                                     this.audioPlayer.play();
                                 } else {
                                     this.audioPlayer.pause();
                                 }
                             }}/></div>
                    <a href={this.props.midiURL} target={'_blank'}>midi</a>
                </div>
            </div>);
    };
}
