import * as React from 'react';
import './App.css';

import Amplify, {I18n} from 'aws-amplify';
import awsconfig from './aws-exports';
import awsapiconfig from './aws-api';
import Upload from "./upload/Upload";

Amplify.configure(Object.assign(awsconfig, awsapiconfig));

class App extends React.Component<{}, {}> {

    public render() {
        return (
            <div className="App">
                <header className="App-header">
                    <p>
                        {I18n.get('Upload recordings for evaluation')}
                    </p>
                </header>

                <Upload />

                <div className="Footer">
                    &copy; Peachnote GmbH
                </div>
            </div>
        );
    };
}

const dict = {
    'zh': {
        'Upload recordings for evaluation': '上传录音以供评估',
        'Select reference recording': '上传参考录音',
        'Upload MP3 Files': '上传MP3文件',
        'Clear': '清除',
        'Upload': '上传',
        'Submit': '提交',
        'Your Name': '你的名字',
        'Name': '名称',
        'Email': '电子邮件地址',
        'Password': '密码',
        'No account? ': '没有账号？',
        'Create account': '创建帐号',
        'Forget your password? ': '忘记密码？',
        'Reset password': '重设密码',
        'Sign in to your account': '登录到您的帐户',
        'Enter your username': '输入你的用户名',
        'Enter your password': '输入您的密码',
        'Have an account? ': '有一个账户？',
        'Sign in': '登入',
        'Create Account': '创建帐号',
        'Username cannot be empty': '用户名不能为空',
        'Create a new account': '创建一个新账户',
        'Reset your password': '重置你的密码',
        'Loading...': '载入中...',
        'processing': '处理...'
    }
};

I18n.putVocabularies(dict);

export default App
