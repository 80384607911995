import {Auth} from 'aws-amplify'

const awsapi = {
    API: {
        endpoints: [
            {
                name: "PianoPerformanceEvaluationAPI",
                endpoint: "https://qzm5an5ic7.execute-api.eu-central-1.amazonaws.com/prod",
                custom_header: async () => {
                    return { Authorization: `Bearer ${(await Auth.currentSession()).accessToken.jwtToken}` }
                }
            },
        ]
    }
};


export default awsapi;
