// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "pianoperformanceeval-20190607101637-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dx9y96zcyyjw4.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:4013c9ad-9017-49a4-a694-45c0144018ef",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GPa0uYHml",
    "aws_user_pools_web_client_id": "5viuaf5o1th44s3kovk3nepp1v",
    "oauth": {},
    "aws_user_files_s3_bucket": "pianoperformanceevalf51edb1486e847089e259c8137416a44-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
